<template>

  <v-container>
    <row-header :msg="msgRowHeader" action="MerchantProductNew"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="merchant_products"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="no"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2 green--text"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  class="red--text"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import { GetRequest, DeleteRequest } from '../../../store/services/users';

export default {
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'Merchant Products List', actionName: 'MerchantProductNew'},
    merchant_products: [],
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Merchant', value: 'merchant_name' },
      { text: 'Category', value: 'category_name' },
      { text: 'Up Selling Price', value: 'up_selling_price', align: 'end' },
      { text: 'Status', value: 'active' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    dataSelected: {},
  }),
  async mounted() {
    console.log('view page admin merchant_products');
    if (this.$store.getters.isLoggedIn) {
      await this.getMerProdData();
      await this.action.$on('delete-data-product', () => {
        this.deleteExecute();
      })
    }
  },
  beforeDestroy() {
    // this.action.$off('delete-data-user');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getMerProdData() {
      this.loading = await this.$store.dispatch('req_data')
      try{
        let response = await GetRequest('merchant-products');
        this.merchant_products = response.data;
        this.merchant_products.forEach((val, i) => {
          val.no = i+1;
          val.active = this.mappingStatus(val.active);
          val.up_selling_price = this.$options.filters.amountFormatNoPrefix(val.up_selling_price);
          val.category_name = val.category.name;
          val.merchant_name = val.merchant.name;
          val.created_by = val.created_by.full_name;
          val.created_at = val.created_at ? this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss') : '-';
        })
        this.loading = await this.$store.dispatch('req_success');
        return true;
      }catch(err){
        this.loading = await this.$store.dispatch('req_failed');
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    editItem (item) {
      this.dataSelected = item;
      this.$router.push({name: 'MerchantProductEdit', params: item})
    },
    deleteItem (item) {
      this.dataSelected = item;
      this.action.$emit('open-dialog-conf-product', true);
    },
    async deleteExecute () {
      // test dah
      await this.$store.dispatch('req_data')
      this.loading = this.$store.getters.isLoading;
      try {
        let response = await DeleteRequest('merchant-products/' + this.dataSelected._id);
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        // console.log(response);
        if (response.data && response.message === 'deleted') {
          alert('delete data success');
          await this.getMerProdData();
          return;
        }
        alert('delete data failed');
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Delete data failed ' + e.message);
      }
    },
    mappingStatus(status) {
      let res;
      if (status === 0) {
        res = 'Not Active';
      } else if (status === 1) {
        res = 'Active';
      } else if (status === 2) {
        res = 'Trouble';
      }

      return res;
    }
  }
}
</script>

<style scoped>

</style>
